<template>
    <div>
        <v-row v-if="rpManager">
            <v-col cols="12">
                <v-card flat color="transparent" class="text-center">
                    <v-card-title>
                        <v-icon large left>mdi-check-all</v-icon>
                        <div class="text-h6 secondary--text">NCU utilization status</div>
                    </v-card-title>
                    <v-card-text>
                        <dashboard-loader v-if="fetching.utilization_status"></dashboard-loader>
                        <template v-else>
                            <dashboard-error v-if="$isError(utilStatus)"></dashboard-error>
                            <template v-else>
                                <v-avatar size="88" :color="utilStatusInfo[utilStatus].color">
                                    <v-icon dark x-large>{{ utilStatusInfo[utilStatus].icon }}</v-icon>
                                </v-avatar>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ utilStatusInfo[utilStatus].label }}</v-list-item-title>
                                        <v-list-item-subtitle class="subtitle">{{ utilStatusInfo[utilStatus].description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col cols="12" lg="4">
                <v-card height="100%" class="d-flex flex-column">
                    <v-card-title>
                        <v-icon large left>date_range</v-icon>
                        <div class="text-h6 secondary--text">Term</div>
                    </v-card-title>
                    <v-container fluid class="pb-8 ma-auto">
                        <v-row class="text-center">
                            <v-col cols="12" sm="6">
                                <div class="text-h5 secondary--text">{{ status.resource_pool_start }}</div>
                                <label class="text--secondary">Start</label>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="6">
                                <div class="text-h5 secondary--text">{{ status.resource_pool_end }}</div>
                                <label class="text--secondary">End</label>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4">
                <v-card height="100%" class="d-flex flex-column">
                    <v-card-title>
                        <v-icon large left>mdi-memory</v-icon>
                        <div class="text-h6 secondary--text">NCU Capacity (NCU)</div>
                    </v-card-title>
                    <div class="pa-8 pt-4 ma-auto text-center text-h4 secondary--text">{{ status.ncu_hours.toLocaleString() }} NCU hours per term year</div>
                    <v-container fluid class="pb-8 ma-auto">
                        <v-row class="text-center">
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ status.burst_ncu_limit.toLocaleString() }}</div>
                                <label class="text--secondary">Burst</label>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ status.base_ncu_limit.toLocaleString() }}</div>
                                <label class="text--secondary">Power save</label>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ status.rtc_ncu_limit.toLocaleString() }}</div>
                                <label class="text--secondary">Round-the-clock</label>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4">
                <v-card height="100%" class="d-flex flex-column">
                    <v-card-title>
                        <v-icon large left>payment</v-icon>
                        <div class="text-h6 secondary--text">Current balance (AC)</div>
                        <v-spacer></v-spacer>
                        <v-dialog v-if="rpManager" v-model="transferDialog" width="500" persistent>
                            <template v-slot:activator="{ on: dialog }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip, attrs }">
                                        <v-btn v-bind="attrs" v-on="{ ...tooltip, ...dialog }" icon large><v-icon color="cyan">mdi-cash-fast</v-icon></v-btn>
                                    </template>
                                    <span>Transfer credits</span>
                                </v-tooltip>
                            </template>
                            <v-card>
                                <v-card-title>Transfer credits</v-card-title>
                                <v-card-text>
                                    <v-form class="mt-4">
                                        <v-select
                                            v-model="transferRecipient"
                                            :items="managedResourcePools"
                                            label="Recipient"
                                            item-text="long_id"
                                            item-value="rpid"
                                            outlined
                                            dense></v-select>
                                        <v-text-field
                                            v-model="creditsToTransferRounded"
                                            v-mask="currencyMask"
                                            outlined
                                            dense
                                            prefix="AC"
                                            persistent-hint
                                            :hint="transferCreditHint"
                                            :rules="[rules.maxValue]"></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" text @click="transferDialog = false" :disabled="transferingCredits">cancel</v-btn>
                                    <v-btn
                                        color="primary"
                                        :disabled="!transferRecipient || creditsToTransferRounded === '0.00'"
                                        :loading="transferingCredits"
                                        @click="transferCredits()">
                                        transfer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-title>
                    <div class="pa-8 pt-4 ma-auto text-center text-h4 secondary--text">
                        {{ currentBalanceRounded.toLocaleString() }}
                    </div>
                    <v-container fluid class="pb-8 ma-auto">
                        <v-row class="text-center">
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ formatAC(status.initial_credits) }}</div>
                                <label class="text--secondary">Initial</label>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ formatAC(status.top_up_credits) }}</div>
                                <label class="text--secondary">Top-up</label>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col cols="12" sm="4">
                                <div class="text-h5 secondary--text">{{ formatAC(status.total_credits_used) }}</div>
                                <label class="text--secondary">Used</label>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col cols="12">
                <v-card flat color="transparent">
                    <v-card-title>
                        <v-icon large left>mdi-order-bool-ascending-variant</v-icon>
                        <div class="text-h6 secondary--text">Additional services</div>
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="4" v-for="(value, service) in status.activated_services" :key="service">
                                <v-card flat color="grey lighten-3" @click="toggleService(service)" :disabled="!rpManager || value">
                                    <v-list two-line color="transparent">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-icon v-if="value" class="primary lighten-1" dark>check_circle</v-icon>
                                                <v-icon v-else class="grey lighten-1" dark>circle</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ services[service].label }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ services[service].description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-10">
            <v-col cols="12">
                <additional-storage :rpid="rpid" :expired="utilStatus === 'expired'" :selectedRP="selectedRP" @refresh="getStatus" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import eventBus from '@/components/EventBus'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { toLocaleFixed } from '@/utils'

const DashboardLoader = () => import('@/components/DashboardLoader.vue')
const DashboardError = () => import('@/components/DashboardError.vue')

const AdditionalStorage = () => import('./AdditionalStorage.vue')

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true
})

export default {
    name: 'MonitoringOverview',
    components: { DashboardLoader, DashboardError, AdditionalStorage },
    props: { rpid: Number, rpManager: Boolean, selectedRP: Object, managedResourcePools: Array },
    data() {
        return {
            fetching: {
                utilization_status: false,
                status: false
            },
            utilStatus: null,
            status: null,
            services: {
                video: {
                    label: 'Video',
                    description: 'Video library uploading',
                    enableFlag: 'vimeo_enabled',
                    overrideFlag: 'video_enabled_override'
                },
                db: {
                    label: 'Database',
                    description: 'Use databases and query data',
                    enableFlag: 'db_enabled',
                    overrideFlag: 'tables_enabled_override'
                },
                hpc: {
                    label: 'Credit-base sizes',
                    description: 'Enable usage of credit-based sizes',
                    enableFlag: 'hpc_enabled',
                    overrideFlag: 'hpc_enabled_override'
                }
            },
            serviceToBeActivated: '',
            utilStatusInfo: {
                in_line: {
                    label: 'All ok!',
                    description: 'Your resource utilization is in line with your contract',
                    icon: 'done_outline',
                    color: 'success'
                },
                overused: {
                    label: 'Action required: resources overused',
                    description:
                        'You have already overused your resource allocation included in your plan. Sales will get in touch with you about upgrading to a larger package.',
                    icon: 'mdi-close-circle',
                    color: 'error'
                },
                expired: {
                    label: 'Expired',
                    description: 'The contract related to this resource pool has expired.',
                    icon: 'mdi-calendar-remove-outline',
                    color: 'error'
                },
                projected_overuse_since_start: {
                    label: 'Attention: projected resource overuse',
                    description:
                        'Your current resource utilization is in line with your contract, but based on your usage since the start of the contract, you will be overusing by the end of the plan period.',
                    icon: 'mdi-alert-circle',
                    color: 'warning'
                },
                projected_overuse_for_30_days: {
                    label: 'Attention: projected resource overuse',
                    description:
                        'Your current resource utilization is in line with your contract, but based on your usage in the last month, you will be overusing by the end of the plan period.',
                    icon: 'mdi-alert-circle',
                    color: 'warning'
                },
                projected_overuse_for_90_days: {
                    label: 'Attention: projected resource overuse',
                    description:
                        'Your current resource utilization is in line with your contract, but based on your usage in the last 3 months, you will be overusing by the end of the plan period.',
                    icon: 'mdi-alert-circle',
                    color: 'warning'
                }
            },
            precision: this.$appConfig?.VUE_APP_DASHBOARD_PRECISION,
            transferDialog: false,
            creditsToTransferRounded: '0.00',
            transferRecipient: null,
            currencyMask,
            rules: {
                maxValue: p => this.maxValue || "Can't be more than available balance."
            },
            transferingCredits: false,
            transferResult: null
        }
    },
    computed: {
        transferCreditHint() {
            return `Credits left after transfer: AC ${this.formatAC(this.currentBalanceRounded - this.creditsToTransferRounded)}`
        },
        maxValue() {
            const left = this.currentBalanceRounded - this.creditsToTransferRounded
            return left >= 0
        },
        creditsToTransfer() {
            return this.creditsToTransferRounded === this.currentBalanceRounded ? this.status.current_balance : this.creditsToTransferRounded
        },
        currentBalanceRounded() {
            return this.formatAC(this.status.current_balance)
        }
    },
    methods: {
        async getUtilizationStatus() {
            if (!this.rpManager) return
            try {
                this.fetching.utilization_status = true
                const { data } = await this.$axios.get(`/resource/${this.rpid}/utilization_status`)
                this.utilStatus = data.utilization_status_in_line
            } catch (error) {
                if (error?.response?.data?.code === 'resource_pool_ended') {
                    this.utilStatus = 'expired'
                } else {
                    this.utilStatus = error
                }
            } finally {
                this.fetching.utilization_status = false
            }
        },
        async getStatus() {
            try {
                this.fetching.status = true
                const { data } = await this.$axios.get(`/resource/${this.rpid}/status`)
                this.status = data
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not fetch utilization data.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            } finally {
                this.fetching.status = false
            }
        },
        toggleService(service) {
            this.$store.dispatch('showGlobalDialog', {
                dialogTitle: `Enabling ${this.services[service].label} service`,
                dialogText: `By enabling the ${this.services[service].label} feature, organization manager(s) will be able to enable it in the organization as well. Please note that the feature cannot be disabled at resource pool level anymore once enabled, only organization level.`,
                dialogAction: ['cancel', 'accept']
            })
            this.serviceToBeActivated = service
        },
        async activateService(service) {
            const serviceLabel = this.services[service].label
            try {
                await this.$axios.patch(`/resource/${this.rpid}`, {
                    [`${this.services[service].enableFlag}`]: true
                })
                this.getStatus()
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Successfully enabled ${serviceLabel} service`,
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Could not activate ${serviceLabel} service. Please contact Nuvolos support if the error persists.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
            this.serviceToBeActivated = ''
        },
        formatAC(value, precision = this.precision) {
            return toLocaleFixed(value, precision)
        },
        async transferCredits() {
            this.transferingCredits = true
            try {
                await this.$axios.post(`/resource/${this.rpid}/transfer`, {
                    target_rpid: this.transferRecipient,
                    amount: this.creditsToTransfer
                })
                this.transferDialog = false
                this.getStatus()
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Credit transfer was successfull!',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'check_circle'
                })
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Credit transfer failed.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
            this.transferingCredits = false
        }
    },
    watch: {
        rpid(to) {
            if (!to) return
            this.getStatus()
            this.getUtilizationStatus()
        }
    },
    mounted() {
        eventBus.$on('global-dialog-accept', () => {
            if (this.serviceToBeActivated) this.activateService(this.serviceToBeActivated)
        })
    },
    destroyed() {
        eventBus.$off('global-dialog-accept')
    }
}
</script>
<style scoped lang="scss">
.subtitle {
    white-space: normal;
}
</style>
